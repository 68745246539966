'use strict';

angular.module('mvpcarrefourApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('main.ranking', {
                url: 'ranking',
                resolve: {
                    _categories: ['Category', function(Category) {
                        return Category.list();
                    }]
                },
                views: {
                    'main': {
                        templateUrl: 'app/ranking/ranking.html',
                        controller: 'RankingCtrl'
                    }
                }
            })

    });
